import * as React from "react";

const WhyChooseUs = (props) => {
    const whyChooseUsListing = [
        {
          count: '8',
          title: 'Year Experience',
        },
        {
          count: '80',
          title: 'Satisfied Clients',
        },
        {
          count: '2,00,000',
          title: 'Development Hours',
        },
        {
          count: '140',
          title: 'Projects Delivered',
        },
    ]
    const {className} = props;
    return (
        <section className={`why-choose-us-sec container-fluid gb-md-pad-top ${className}`}>
            <div className="container">
                <div className="why-choose-heading common-heading text-center">
                    <h2><span>Why Choose</span> us?</h2>
                </div>
                <div className="row">

                    {whyChooseUsListing.map((row, index) => (
                        <div className="col-md-3 col-6" key={index}>
                            <div className="why-choose-common-col">
                                <div className="fa-icon-wrap">
                                    <h3>{row.count}</h3>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </div>
                                <h5>{row.title}</h5>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default WhyChooseUs;
