import {ASSETS} from "../common/images";
import * as React from "react";
import Slider from "react-slick";
const Testimonials = () => {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-our-work-next-prev-btn our-work-prev-btn" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0}
            type="button"
        >
            <img src={ASSETS.SERVICES.SliderArrowLeft} className="bg-sm-img" alt="Arrow|GeekyBones" width={'20'} height={'20'} />
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-our-work-next-prev-btn our-work-next-btn" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1}
            type="button"
        >
            <img src={ASSETS.SERVICES.SliderArrowRight} className="bg-sm-img" alt="Arrow|GeekyBones" width={'20'} height={'20'} />
        </button>
    );

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };
    const testimonialList = [
        {
            clientMessage: 'I have worked with the GeekyBones Lab team for almost three years and am extremely happy with the results. They are all extremely easy to work with, take care to understand the spec and always deliver on time, with results typically exactly matching the original specification. Subsequent changes are delivered quickly and without fuss. They are all incredibly responsive to urgent fixes, even when out of hours. Since working with the Geekybones team, any stress related to development that I used to have, is now a very distant memory. I have always been confident that the technical side of the business is in safe hands. They all work diligently through tasks set and can be relied on entirely to work independently while I focus on other parts of running the business. I am incredibly grateful to have found such a strong and professional team who are willing to put in their best at all times. Recommended 10/10!',
            clientName: 'A. Harris',
        },
        {
            clientMessage: 'GeekyBones did an awesome job! The team was very quick to communicate and had quick turnaround times! They were diligent in making sure he did the job exceptionally well! Would use them again as well as recommend them to others!',
            clientName: 'Y. Wilson',
        },
        {
            clientMessage: 'If I can give more than 5 stars for each of the above categories, I would love to give; that is the way GeekyBones delivered the project. Manoj is very professional, very patient (can tolerate any amount of trouble), understandable (no need to explain much), creative, good listener, approachable, people person, innovative, and always looking for new solutions and fresh ideas that can advance his knowledge to exceed his client’s expectations and goals. When I needed help, he was eager to assist me. Manoj has ingenious web design and development skills with an eye for detail. He is a passionate designer and always seems to have great awareness of new creative strategies and technology. His design skills, communication and openness to solving problems are all valuable traits. He is always more than happy to do corrections and tries his best to deliver a project that is meaningful, user friendly and delivers the project in time. I strongly recommend Manoj to any organisation. He will be a great asset to any Organisation and also a great asset to any team... I strongly recommend him as an exceptionally talented designer. He has a strong work ethic, and he will be a real gem for any organisation. I wish him all the best, and I am also eager to give more work',
            clientName: 'A Ram',
        },
        {
            clientMessage: 'Great working with Daishy, very fast initial results, quick learning, great communication and dedication to getting the job done. Very happy.',
            clientName: 'T. Ward',
        },
        {
            clientMessage: 'GeekyBones team has 100% met the required specifications. So that the result was quick and largely satisfactory.',
            clientName: 'D. Giorgio',
        },
        {
            clientMessage: 'GeekyBones team realized the project exactly as we wanted it. The processing times were higher than expected, but on the other hand, we were in no hurry. So we have privileged the final result, instead of a hasty development.',
            clientName: 'D. Giorgio',
        },
        {
            clientMessage: 'Great team. Completed a challenging Express JS assignment and was able to generate Open API Docs for my dev portal. Would recommend!',
            clientName: 'M. Davis',
        },
        {
            clientMessage: 'A committed professional. Did work even when it involved doing things not initially identified, without any hesitation or asking for any additional payment. Did a good job and within the accepted budget. Great work ethics with great work quality!!',
            clientName: 'S. Prasad',
        },
    ]
    return (
        <section className="our-work-slider container-fluid gb-md-pad-ver">
            <div className="our-work-heading common-heading text-center">
                <h2>Statements from our <span>Happy Customers</span></h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sliders one">
                            <Slider {...settings}>
                                {testimonialList.map((row, index) => (
                                    <div key={index} className="our-work-common">
                                        <div className="our-work-sec">
                                                        <div className="our-work-second-col">
                                                            <div className="main-content">
                                                                <p>
                                                                    <span className="start-quote">&#8220;</span>
                                                                    {row.clientMessage}
                                                                    <span className="end-quote">&#8221;</span></p>
                                                            </div>
                                                            <div className="logo-heading-wrap">
                                                                    <h5>- {row.clientName}</h5>
                                                            </div>
                                                        </div>
                                            </div>
                                    </div>
                                ))}
                            </Slider>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Testimonials;
