import {ASSETS} from "../common/images";
import * as React from "react";
import Slider from "react-slick";

const LatestTechnologiesWeServe = () => {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-tech-next-prev-btn tech-prev-btn" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0}
            type="button"
        >
            <img src={ASSETS.SERVICES.SliderArrowLeftWhite} alt="Arrow|GeekyBones" width={'20'} height={'20'} />
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-tech-next-prev-btn tech-next-btn" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1}
            type="button"
        >
            <img src={ASSETS.SERVICES.SliderArrowRightWhite} width={'20'} height={'20'} alt="Arrow|GeekyBones" />
        </button>
    );

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };
    return (
        <section className="slich-pos text-center latest-tech-slider-wrapper gb-md-pad-top">
            <div className="expert-tech-heading common-heading">
                <h2><span>Technologies</span> we serve</h2>
                <p>GeekyBones unleash bespoke technology, providing impactful and efficient business solutions. We leverage the up to the minute technologies that help in maximizing the productivity and domination of your business in the marketplace. Our innovative solutions serve a wide array of requirements, delighting users across multiple domains.</p>
<p>So let’s join hands and move together towards a successful business journey.</p>
            </div>

            <div className="container">
                <div className="slider three">
                    <Slider {...settings}>
                        <div className="common-main-div">
                            <div className="technology-expert-sec container-fluid">
                                <div className="container">
                                    <div className="row slider-row">
                                        <div className="col-md-6">
                                            <div className="expert-tech-col-first">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="common-div common-div-1">
                                                            <img src={ASSETS.HOME.FlutterSvg} className="common-expert-tech-img" alt="flutter|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Flutter</h5>
                                                            <p>Create and deploy high-fidelity apps for Android, Windows and iOS, from a single codebase. Get beautiful, prompt, natively compiled flutter apps.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-2">
                                                            <img src={ASSETS.HOME.AndroidLogoHome} className="common-expert-tech-img" alt="android|GeekyBones"
                                                                width={'50'} height={'50'}
                                                            />
                                                            <h5>Android</h5>
                                                            <p>Get the most out of the Android ecosystem, crafting apps and programs with high-end features.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-3">
                                                            <img src={ASSETS.HOME.ReactHome} className="common-expert-tech-img" alt="reactjs|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>React Native</h5>
                                                            <p>Find the tailored React Native solutions resonating with your business needs. We create stunning business empowered cross-platform apps for Android and iOS.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-4">
                                                            <img src={ASSETS.HOME.SwiftHome} className="common-expert-tech-img" alt="swift|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Swift</h5>
                                                            <p>Take your projects to the advanced level with our Swift/iOS app experts. We build immaculate and custom swift app development.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="expert-tech-col-second">
                                                <img src={ASSETS.HOME.Mobile2Home}  className="mobile-laptop-img" alt="mobile|GeekyBones"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="common-main-div">
                            <div className="technology-expert-sec container-fluid">
                                <div className="container">
                                    <div className="row slider-row">
                                        <div className="col-md-6">
                                            <div className="expert-tech-col-first">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="common-div  common-div-1">
                                                            <img src={ASSETS.PORTFOLIO.SymfonyBlack}  className="common-expert-tech-img" alt="symfony|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Symfony</h5>
                                                            <p>Achieve success through all the stages of creating a website on Symfony. Our dedicated symfony developers have a proven track in delivering successful projects.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-2">
                                                            <img src={ASSETS.HOME.LaravelHome}  className="common-expert-tech-img" alt="laravel|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Laravel</h5>
                                                            <p>Build feature-rich applications and websites with the most famous PHP web framework, Laravel. We offer reliable and scalable Laravel development services for businesses.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-3">
                                                            <img src={ASSETS.HOME.CodineitherHome}  className="common-expert-tech-img" alt="codeigniter|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>CodeIgniter</h5>
                                                            <p>Ignite and amplify your business with Codeigniter development solutions. It is a powerful open-source PHP framework that builds full-featured dynamic websites.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-4">
                                                            <img src={ASSETS.HOME.CakephpHome}  className="common-expert-tech-img" alt="cakephp|GeekyBones" width={'50'} height={'50'} />
                                                            <h5> CakePHP</h5>
                                                            <p>Find the stunning CakePhp web application to grow above the competition. We provide a tasty treat to your business with a fast, simple yet powerful CakePhp framework.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="expert-tech-col-second">
                                                <img src={ASSETS.HOME.TechSportGear}  className="mobile-laptop-img" alt="Sport-Gear-Swag" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="common-main-div">
                            <div className="technology-expert-sec container-fluid">
                                <div className="container">
                                    <div className="row slider-row">
                                        <div className="col-md-6">
                                            <div className="expert-tech-col-first">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-1">
                                                            <img src={ASSETS.HOME.WordpressHome}  className="common-expert-tech-img" alt="wordpress|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>WordPress</h5>
                                                            <p>Begin your journey with powerful digital solutions and user experience with our wordpress development services. We develop high-performing websites for better business results.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-2">
                                                            <img src={ASSETS.HOME.Shopify}  className="common-expert-tech-img" alt="shopify|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Shopify</h5>
                                                            <p>Automate your business with customised Shopify services. We create reliable Shopify integrations, connecting your Shopify storefront with a range of applications and systems.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-3">
                                                            <img src={ASSETS.HOME.MagentoHome}  className="common-expert-tech-img" alt="magento|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Magento</h5>
                                                            <p>Propel and empower your e-Commerce business. Our developers take full advantage of Magento development technology to upgrade your platform that stands out from the competition.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="common-div common-div-4">
                                                            <img src={ASSETS.HOME.OpenCart} className="common-expert-tech-img" alt="opencart|GeekyBones" width={'50'} height={'50'} />
                                                            <h5>Opencart</h5>
                                                            <p>Get remarkable OpenCart development services. Our experts craft responsive OpenCart eCommerce stores using the latest trends and emerging needs of the end-users.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="expert-tech-col-second">
                                                <img src={ASSETS.HOME.WordpressImg} className="mobile-laptop-img" alt="Mavrik3|GeekyBones"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}
export default LatestTechnologiesWeServe;
